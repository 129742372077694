/* blog */
.blog-content {
  margin: 0 auto;
  max-width: 1280px;
  width: 1280px;
  display: flex;
  gap: 50px;
  justify-content: center;
  padding: 30px;
}

.blog-list {
  flex: 3;
  display: flex;
  gap: 20px;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
}

.blog-content-list {
  display: flex;
  gap: 30px;
  flex-direction: column;
}

@media screen and (max-width: 960px) {
  .blog-content {
    width: 100%;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
  }

  /* .blog-list {
    padding: 10px;
  } */
  .blog-content-list {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
}