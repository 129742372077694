.form-group {
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  flex-direction: column;
  font-size: 18px;
  padding: 10px;
}

.form-group-line {
  text-align: left;
  display: flex;
  gap: 10px;
  flex-direction: column;
  margin-bottom: 10px;
}

.form-group div p {
  color: #BF9000;
}

.input-required {
  color: #BF9000;
  font-size: 16px;
  font-weight: bold;
}

.message-wrapper {
  background-color: #BF9000;
}

.form-group input,
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
  font-size: 14px;
}

.message-button {
  width: 100%;
  padding: 6px;
  border-radius: 8px;
  border: none;
  background-color: #BF9000;
  border: #BF9000 2px solid;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.message-button:hover {
  background-color: #F3F3F3;
  /* border: #BF9000 2px solid; */
  color: #BF9000;
}

.form-group .error {
  /* background-color: #BF9000; */
  border-radius: 8px;
  padding: 20px;
  color: #BF9000;
  border: #BF9000 2px solid;
}

.form-group .success {
  background-color: #4e4e4e;
  border-radius: 8px;
  padding: 20px;
  color: #fff;
}

.message-result {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}