.cart-wrapper {
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  /* gap: 20px; */
  font-family: 'Lexend', sans-serif;
  text-align: left;
  gap: 20px;
  background-color: #fff;
  position: relative;
}

.cart-wrapper img {
  max-width: none;
}

.cart-header {
  width: 100%;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
}

.cart-button-continue {
  background-color: #fff;
  padding: 5px 5px;
  border-radius: 50px;
  border: 1.5px solid #000;
  color: #000;
  font-size: 16px;
  line-height: 25px;
  font-weight: 450;
  text-transform: uppercase;
  display: none;
  gap: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 230px;
}

.cart-main {
  /* width: 100%; */
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-top: 80px;
  padding-bottom: 80px;
}

.cart-content {
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.cart-content-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cart-item-store {
  width: 100%;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
  /* gap: 10px; */
  /* background-color: #f5f5f5;
  padding: 10px; */
}

.cart-item-store h3 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

.cart-item-store p {
  font-size: 16px;
  font-weight: 300;
}

.cart-summary {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.cart-summary-reserve {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.cart-content-header {
  color: #000;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cart-content-header h3 {
  font-size: 32px;
  font-weight: 500;
  text-transform: uppercase;
}

.cart-content-header p {
  font-size: 18px;
  font-weight: 400;
  line-height: 40px;
}

.cart-summary-header {
  width: 100%;
}

.cart-summary-header h3 {
  font-size: 32px;
  font-weight: 500;
}

.cart-summary-header p {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}

.cart-summary-total {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.cart-total-line {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-total-border-bottom {
  width: 100%;
  display: block;
  border-bottom: #bbb 1px solid;
  height: 10px;
}

.cart-item-list {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.cart-item-line {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.cart-item-left {
  display: flex;
  gap: 20px;
}

.cart-item-left img {
  width: 197px;
  height: 194px;
  object-fit: cover;
  border: 1.5px solid #eee;
  border-radius: 10px;
}

.cart-item-info {
  /* max-width: 100%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  padding-bottom: 5px;
}

.cart-item-info h4 {
  word-wrap: break-word;
}

.cart-item-info button {
  text-align: left;
  color: red;
}

.cart-item-price {
  text-align: right;
}

.cart-item-price h4 {
  font-size: 18px;
  font-weight: 400;
}

.cart-item-price span {
  font-size: 14px;
}

.cart-item-buttons {
  width: 100%;
  display: flex;
  gap: 10px;
}

.cart-item-select {
  min-width: 100px;
  border: 1px solid #333;
  padding: 5px 5px;
  /* width: 100%; */
  border-radius: 25px;
  cursor: pointer;
  font-size: 14px;
  background-color: #fff;
  font-weight: 300;
}

.cart-item-input {
  max-width: 100px;
  border: 1px solid #333;
  padding: 5px 10px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 14px;
  background-color: #fff;
  font-weight: 300;
}

.cart-item-info-header {
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: space-between;
}

.cart-item-info-header h4 {
  font-size: 18px;
  font-weight: 300;
  margin: 0;
  padding: 0;
}

.cart-item-info-header h4 span {
  /* break line */
  font-size: 14px;
  display: block;
  color: #717070;
}

.cart-summary-other {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  gap: 20px;
  text-align: right;
}

.cart-summary-other h4 {
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.cart-summary-input {
  width: 425px;
  height: 37px;
  border: 1px solid #000;
  padding: 5px 15px;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.cart-summary-input label {
  line-height: 25px;
  font-size: 18px;
  font-weight: 400;
  color: #a6a6a6;
}

.cart-summary-input input {
  width: 100%;
  border: none;
  background-color: transparent;
  font-size: 14px;
  padding: 5px 5px;
  font-weight: 300;
}

.cart-summary-input input:focus {
  outline: none;
}

.cart-summary-other button {
  width: 251px;
  height: 42px;
  background-color: #e4b456;
  color: #fff;
  border-radius: 25px;
  text-transform: uppercase;
}

.cart-summary-other button:hover {
  border: #e4b456 2px solid;
  background-color: #fff;
  color: #e4b456;
}

/* Custom Checkbox */
.custom-checkbox {
  background-color: #fff;
  min-width: 18px;
  -webkit-appearance: none;
  /* Remove default appearance */
  -moz-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 1px solid #bebebe;
  /* border-radius: 4px; */
  outline: none;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}

.custom-all-checkbox {
  background-color: #fff;
  min-width: 13px;
  -webkit-appearance: none;
  /* Remove default appearance */
  -moz-appearance: none;
  appearance: none;
  width: 13px;
  height: 13px;
  border: 1px solid #bebebe;
  /* border-radius: 4px; */
  outline: none;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;
}

.custom-disabled-checkbox {
  background-color: #9c9c9c;
  min-width: 18px;
  -webkit-appearance: none;
  /* Remove default appearance */
  -moz-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 1px solid #bebebe;
  /* border-radius: 4px; */
  outline: none;
  transition: background-color 0.3s ease-in-out;
  cursor: not-allowed;
}

.custom-checkbox:checked::before {
  content: url('/public/images/Checkboxmark.png');

  /* check mark symbol Unicode */
  display: block;
  text-align: center;
  font-size: 16px;
  line-height: 18px;
  color: #202020;
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.custom-all-checkbox:checked::before {
  content: url('/public/images/Checkboxmark-small.png');

  /* check mark symbol Unicode */
  display: block;
  text-align: center;
  font-size: 10px;
  color: #202020;
}

.custom-all-checkbox:checked {
  /* border: none; */
  border: 1px solid #e4b456;
  background-color: #e4b456;
  /* Change background color when checked */
}

.custom-checkbox:checked {
  /* border: none; */
  border: 1px solid #e4b456;
  background-color: #e4b456;
  /* Change background color when checked */
}

.cart-content-continue {
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-top: #000 1px solid;
  border-bottom: #000 1px solid;
}

.cart-content-continue h4 {
  font-size: 18px;
  text-transform: uppercase;
}

.cart-content-continue p {
  font-size: 20px;
}

.cart-content-continue button {
  width: 150px;
  height: 42px;
  font-size: 15px;
  background-color: #e4b456;
  color: #fff;
  border-radius: 25px;
  text-transform: uppercase;
}

.cart-content-continue button:hover {
  border: #e4b456 2px solid;
  background-color: #fff;
  color: #e4b456;
}

@media screen and (max-width: 960px) {
  .cart-content-continue {
    display: flex;
  }

  .cart-button-continue {
    display: flex;
  }

  .cart-main {
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .cart-content {
    width: 100%;
    padding: 10px;
  }

  .cart-summary {
    width: 100%;
    padding: 10px;
  }

  /* .cart-item-line {
    flex-direction: column;
  } */

  .cart-item-left {
    gap: 5px;
  }

  .cart-item-price {
    text-align: right;
  }

  .cart-item-price h4 {
    font-size: 16px;
    font-weight: 550;
  }

  .cart-item-price span {
    font-size: 14px;
  }

  .cart-item-left img {
    width: 125px;
    height: 125px;
  }

  .cart-summary-input {
    width: 100%;
  }

  .cart-content-header {
    gap: 5px;
  }

  .cart-content-header h3 {
    font-size: 24px;
    padding: 0;
    margin: 0;
  }

  .cart-content-header p {
    font-size: 10px;
    line-height: 20px;
  }

  .cart-item-store h3 {
    font-size: 13px;
    padding: 0;
    margin: 0;
  }

  .cart-item-store p {
    font-size: 10px;
    padding: 0;
    margin: 0;
  }

  .cart-item-list {
    padding-top: 30px;
    padding-bottom: 30px;
    gap: 30px;
  }

  .cart-item-info-header h4 {
    font-size: 15px;
  }

  .cart-item-info button {
    font-size: 12px;
  }

  .cart-summary-other h4 {
    font-size: 15px;
  }
}