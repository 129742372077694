.retail-hero {
  min-height: calc(100vh - 80px)
}

.retail-ul {
  text-align: left;
  padding-left: 20px;
}

.retail-ul li {
  list-style-image: url(/public/images/Bullet.png);
  margin-bottom: 15px;
}

/* bullet */
.retail-ul li span {
  color: #727272;
  font-weight: 300;
  padding-left: 15px;

}

/* text */

.logos img {
  width: 100px;
  aspect-ratio: 16/10;
  object-fit: contain;
}

/* styles.css */

.radio-button {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 2px solid #999;
  border-radius: 50%;
  margin-right: 10px;
  transition: background-color 0.3s, border-color 0.3s;
}

.radio-button.checked {
  background-color: #E4B456;
  border-color: #E4B456;
  position: relative;
}

.radio-button.checked::before {
  content: url('/public/images/Check.png');
  font-weight: 900;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.plan-table {
  margin: auto 0;
  width: 960px;
  margin-top: 20px;
}

.plan-table tr {
  /* border-top: 1px solid black; */
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  border-collapse: collapse;
}

.plan-table th {
  text-align: center;
  font-weight: 300;
  width: 400px;
}

.plan-table strong {
  font-weight: 600;
}

th.plan-big-title {
  text-align: left;
  font-size: 54px;
  line-height: 54px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: -5px;
}

th.plan-title {
  width: 200px;
  font-size: 34px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: -3px;
}

.plan-table th.plan {
  text-align: center;
  font-weight: 300;
  width: 200px;
}

.plan-table th.left {
  text-align: left;
  font-weight: 300;
}

.plan-table th,
td {
  padding: 10px;
  /* border-left: 1px solid black; */
  border-right: 1px solid black;
  border-collapse: collapse;
}

.plan-table-mobile {
  margin: auto 0;
  width: 100%;
  margin-top: 20px;
}

.plan-table-mobile tr {
  /* border-top: 1px solid black; */
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  border-collapse: collapse;
}

.plan-table-mobile th {
  text-align: center;
  font-weight: 300;
  /* width: 400px; */
}

.plan-table-mobile th.title {
  font-weight: 600;
}

.plan-table-mobile th.plan {
  text-align: center;
  font-weight: 300;
  width: 200px;
}

th.plan-left-mobile {
  border-left: none;
  text-align: left;
  font-weight: 300;
  font-size: 12px;
}

th.plan-left-mobile-width {
  border-left: none;
  text-align: left;
  font-weight: 300;
  font-size: 12px;
}

td.plan-inventory-func-mobile {
  font-size: 14px;
  font-weight: 400;
}

.plan-table-mobile th,
td {
  padding: 10px;
  /* border-left: 1px solid black; */
  border-right: 1px solid black;
  border-collapse: collapse;
}

th.plan-title-mobile {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: -1px;
}

@media screen and (max-width:1279px) {
  .logos img {
    scale: 1.25
  }

  .plan-table {
    margin: auto 0;
    width: 100% !important;
  }

  .plan-title {
    width: 100px !important;
    font-size: 13px !important;
    letter-spacing: normal !important;
  }

  .plan-table th {
    font-size: 12px !important;
  }

  .retail-hero {
    align-items: center
  }
}