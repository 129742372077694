.cart-empty {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-top: 80px;
    padding-bottom: 150px;
    align-items: center;
    justify-content: center;
}

.cart-empty h3 {
    font-size: 32px;
    font-weight: 500;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
}

.cart-empty img {
    width: 170px;
}

.cart-empty button {
    width: 225px;
    height: 42px;
    background-color: #e4b456;
    color: #fff;
    border-radius: 25px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 400;
}

.cart-empty button:hover {
    border: #e4b456 2px solid;
    background-color: #fff;
    color: #e4b456;
}

.cart-empty p {
    font-size: 18px;
    font-weight: 600;
}

.cart-empty p a {
    color: #e4b456;
    text-decoration: underline;
}

@media screen and (max-width: 960px) {
    .cart-empty {
        width: 100%;
        padding: 20px;
    }

    .cart-empty h3 {
        font-size: 20px;
    }

    .cart-empty p {
        font-size: 14px;
        text-align: center;
    }
}