.login-wrapper {
  /* margin: 0 auto; */
  width: 100%;
  background-color: #fff;
  height: 100vh;
  /* background-color: #f5f5f5; */
}

.login-top {
  text-align: left;
  padding: 20px;
  display: flex;
  gap: 10px;
  font-weight: bold;
  /* justify-content: center; */

  align-items: center;
}

.login-main {
  margin: 0 auto;
  width: 100%;
  max-width: 550px;
  padding: 10px;
}

.login-main-logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-main-form h3 {
  font-size: 1.5rem;
  line-height: 2rem;
  color: #000;
  text-align: center;
}

.login-form-input {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: none;
  background-color: #fff;
  color: #000;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.login-form-input label {
  /* padding-left: 10px; */
  text-align: right;
  width: 75px;
}

.login-form-input input {
  padding: 5px;
}

.login-form {
  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.login-button {
  width: 100%;
  padding: 6px;
  border-radius: 8px;
  border: none;
  background-color: #bf9000;
  border: #bf9000 2px solid;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.login-button:hover {
  background-color: #f3f3f3;
  /* border: #BF9000 2px solid; */
  color: #bf9000;
}

.login-result {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login-result p {
  font-size: 14px;
  font-weight: none;
  color: #fff;
}

/* the hasFeedback icon */
.ant-form-item-feedback-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}


.login-main-form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  /* background-color: #4E4E4E; */
  border-radius: 10px;
  padding: 20px;
  align-items: center;
  justify-content: center;
  color: #fff;
}



.ant-input-affix-wrapper .ant-input-suffix>*:not(:last-child) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-remember-forgot {

  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #a6a6a6;
}

.login-remember-me {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 960px) {
  .login-main {
    width: 95%;
    max-width: none;
  }
}