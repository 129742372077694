.download-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-family: 'Lexend', sans-serif;
}



.download-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}

.download-header h1 {
    font-size: 2rem;
    font-weight: 500;
}

.download-header p {
    font-size: 16px;
    font-weight: 500;
}

.download-icons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.download-qr {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.download-qr p {
    font-size: 20px;
    font-weight: 400;
}