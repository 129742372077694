.header-location {
    min-width: 120px;
    font-size: 14px;

}
.google-map {
    width: 100%;
    height: 100%;
    border-radius: 15px;
}

.location-wrapper {
    max-width: 400px;
}

.location-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.location-current {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
}

.location-change {
    display: flex;
    justify-content: space-between;
}

.location-input {
    width: 100%;
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 8px 0 0 8px;
    padding: 0 10px;
    font-size: 16px;
}

.location-button {
    width: 100%;
    max-width: 100px;
    padding: 5px;
    border-radius: 0 8px 8px 0;
    border: none;
    background-color: #BF9000;
    border: #BF9000 2px solid;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
}

.location-button:hover {
    background-color: #F3F3F3;
    /* border: #BF9000 2px solid; */
    color: #BF9000;
}

.header-address span {
    white-space: 'nowrap';
}

@media screen and (max-width: 630px) {
    

    .location-text {
        font-size: 13px;
    }
}