.policy-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  /* gap: 20px; */
  font-family: 'Lexend', sans-serif;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #FFF;
}

.policy-section {
  width: 100%;
  margin: 0 auto;
  padding-left: 50px;
  padding-right: 150px;
  display: flex;
  /* justify-content: center;
  align-items: center; */
  gap: 50px;
  justify-content: space-between;
}

.policy-side {
  /* flex: 1; */
  text-align: left;
  padding-top: 125px;
  position: relative;
  width: 150px;
}

.policy-side-list {
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.policy-side h3 {
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.policy-content {
  /* flex: 4; */
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.policy-content-item {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.policy-content-item h3 {
  font-size: 64px;
  font-weight: 500;
}

.policy-content-item p {
  font-size: 18px;
  line-height: 22.5px;
  font-weight: 400;
}

@media screen and (max-width: 960px) {
  .policy-wrapper {
    padding-top: 10px;
  }

  .policy-section {
    width: 100%;
    padding: 20px;
    flex-direction: column;
    gap: 20px;
  }

  .policy-side {
    padding-top: 0;
  }

  .policy-side-list {
    position: relative;
    gap: 10px;
  }

  .policy-content-item h3 {
    font-size: 40px;
  }
}