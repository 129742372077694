.filter-category {
  color: #fff;
  border-bottom: #727272 1px solid;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.filter-category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-bottom: 10px;
}

.filter-category-header-right {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.filter-category h3 {
  margin-bottom: 10px;
}

.filter-category-tree {
  width: 100%;
  color: #fff;
  background-color: #202020;
  font-family: 'Lexend', sans-serif;
}

.ant-tree .ant-tree-switcher {
  width: 15px;
  line-height: 20px;
  text-align: left;
}

.ant-tree .ant-tree-switcher .ant-tree-switcher-icon {
  font-size: 12px;
}


.filter-category-search button {
  background-color: #202020;
  border: none;
  color: #fff;
  font-size: 12px;
  font-family: 'Lexend', sans-serif;
  cursor: pointer;
  border: #fff 1px solid;
}

.filter-category-search .anticon {
  color: #fff;
}

.site-tree-search-value {
  color: #f50;
}

.filter-item-header-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-family: 'Lexend', sans-serif;
}

.filter-item-header-all {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.filter-item-search-bar {
  width: 100%;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  /* margin-bottom: 10px; */
  background-color: #fff;
  border-radius: 25px;
  color: #202020;
  gap: 5px;
}

.filter-item-search-bar input {
  /* padding-left: 5px; */
  width: 100%;
  border: 0;
  margin: 0;
}

.filter-item-search-bar input:focus {
  outline: none;
}

.site-tree-search-value {
  color: #f50;
}




.store-profile-search-bar {
  width: 100%;
  height: 35px;
  /* line-height: 40px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #fff;
  border-radius: 25px;
  border: #202020 1px solid;
  color: #202020;
  gap: 5px;
  font-weight: 300;
}

.store-profile-search-bar input {
  padding-left: 5px;
  width: 100%;
  border: 0;
  margin: 0;
}

.store-profile-search-bar input:focus {
  outline: none;
}