/* near product section */
.product {
  position: relative;
  width: 100%;
  max-width: 1228px;
  margin: 0 auto;
  /* padding: 0 10px; */
  /* margin-top: 65px; */
  /* margin-bottom: 65px; */
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.truncate-multiline {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Number of lines to show before truncating */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.product h2 {
  font-size: 22px;
  font-weight: 400;
  /* margin-bottom: 20px; */
  text-align: left;
  text-transform: uppercase;
}

.product-list {
  display: flex;
  gap: 25px;
  overflow-x: hidden;
}

.product-item {
  width: 100%;
  min-width: 235px;
  max-width: 235px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  gap: 10px;
}

.product-item-icon {
  position: absolute;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  left: 0;
  background-color: #e4b456;
  color: #fff;
  font-size: 25px;
  font-weight: 300;
  border-radius: 10px 0 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #e4b456 1.5px solid;
  padding-bottom: 5px;
  cursor: pointer;
  z-index: 2;
}

.product-item-icon:hover {
  background-color: #fff;
  color: #e4b456;
}

.product-item-img {
  min-width: 235px;
  position: relative;
}

.product-item-img img {
  width: 100%;
  height: 235px;
  border-radius: 10px;
  border: #ccc 1.5px solid;
}

.product-item-info {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
}

.product-item-info h3 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 5px;
  line-height: 22.5px;
  min-height: 45px;
}

.product-item-info-store {
  font-size: 13px;
  font-weight: 300;
  line-height: 16.25px;
}

.product-item-info-review {
  display: flex;
  align-items: center;
  color: #ccc;
  /* gap: 5px; */
}

.product-item-info-price {
  font-size: 22px;
  font-weight: 400;
  line-height: 27.5px;
  /* position: relative; */
  display: flex;
}

.product-item-info-price::before {
  font-size: 14px;
  font-weight: 300;
  /* position: absolute; */
}

.product-item-info-available {
  font-size: 13px;
  font-weight: 300;
}

.product-more {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: #cd9426;
}

.product-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}



@media screen and (max-width: 960px) {
  .product {
    width: 100%;
    padding: 0 10px;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .product h2 {
    font-size: 15px;
  }

  .product-list {
    overflow-x: auto;
    gap: 10px;
  }

  .product-item {
    min-width: 150px;
  }

  .product-item-img {

    min-width: 150px;
  }

  .product-item-img img {
    height: 150px;
  }
}