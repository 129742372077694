.feature-wrapper {
  width: 100%;
  height: 400px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;

}

.feature-wrapper p {
  color: white;
}

@media screen and (max-width: 960px) {
  .feature-wrapper {
    height: 250px;
  }
}