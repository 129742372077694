.contact-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: 20px; */
  font-family: 'Lexend', sans-serif;
  background-color: #fff;
}

.contact-section {
  width: 815px;
  margin: 0 auto;
  padding: 150px 50px;
  display: flex;
  /* justify-content: center;
    align-items: center; */
  gap: 50px;
}

.contact-section h1 {
  flex: 1;
  font-size: 48px;
  font-weight: 400;
  text-align: right;
}

.contact-form {
  text-align: left;
  display: flex;
  justify-content: center;
}

.contact-form form {
  max-width: 460px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.contact-form-input {
  width: 100%;
  padding: 5px 15px;
  border: 2px solid #000;
  border-radius: 25px;
  background-color: #fff;
  display: flex;
}

.contact-form-input label {
  font-size: 16px;
  font-weight: 400;
  width: 80px;
}

.contact-form-input input {
  font-weight: 300;
  width: 100%;
  border: none;
  background-color: transparent;
  color: #333;
}

.contact-form-input input:focus {
  outline: none;
}

.contact-form-two-columns {
  display: flex;
  gap: 10px;
}

.contact-form button {
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 9999px; /* rounded-full */
  border: 2px solid black;
  background-color: white;
  color: #1a202c; /* text-base */
  font-size: 18px;
  font-weight: 500; /* font-medium */
  cursor: pointer;
  text-transform: uppercase;
}

.contact-form button:hover {
  background-color: white;
  border-color: #e4b456; /* border-fitzba-bright-gold-static */
  color: #e4b456; /* text-fitzba-bright-gold-static */
}

.contact-form-textarea {
  width: 100%;
  padding: 5px 15px;
  border: 2px solid #000;
  border-radius: 25px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.contact-form-textarea label {
  font-size: 1rem;
  font-weight: 500;
}

.contact-form-textarea textarea {
  width: 100%;
  border: none;
  background-color: transparent;
  color: #666;
  padding: 0;
  margin: 0;
}

.contact-form-textarea textarea:focus {
  outline: none;
}

.contact-error {
  /* background-color: #BF9000; */
  border-radius: 8px;
  padding: 20px;
  color: #bf9000;
  border: #bf9000 2px solid;
  margin-top: 20px;
}

.contact-success {
  background-color: #4e4e4e;
  border-radius: 8px;
  padding: 20px;
  color: #fff;
  margin-top: 20px;
}

.contact-result {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

@media screen and (max-width: 960px) {
  .contact-section {
    width: 100%;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
  }

  .contact-section h1 {
    text-align: left;
    font-size: 36px;
    padding-bottom: 10px;
  }

  .contact-form {
    width: 100%;
  }

  .contact-form h1 {
    flex: auto;
  }

  .contact-form form {
    max-width: 460px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center; /* Add this line */
  }

  .contact-form-two-columns {
    flex-direction: column;
  }

  .contact-form button {
    width: 100%; /* Adjust as needed */
    margin-left: auto;
    margin-right: auto;
  }
}
