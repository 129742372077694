.topbar-friday {
    width: 100%;
    background-color: #FABA3D;
    /* padding-top: 10px; */
}

.topbar-friday-bg {
    margin: 0 auto;
    width: 100%;
    max-width: 1920px;
    background-image: url('/public/images/christmas/topheader.png');
    height: 73px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* hide text */
    /* text-indent: 100%;
    white-space: nowrap;
    overflow: hidden; */
    cursor: pointer;
}

.topbar-friday-mobile {
    display: none;
    /* padding: 20px 30px 10px 30px; */
    background-color: #FABA3D;
    /* background-Image: url('/public/images/christmas/star.png');
    background-repeat: no-repeat;
    background-size: 70px 70px;
    background-position: right -15px top -15px; */
}

.topbar-friday-bg-mobile {
    background-color: black;
    /* padding: 10px; */
    cursor: pointer;
}

.star-left {
    top: 70px;
    left: -10px;
    position: absolute;
    width: 70px;
}

.star-right {
    top: -15px;
    right: -15px;
    position: relative;
    width: 70px;
}

.topbar-friday-bg-mobile img {
    width: 100%;
}

.topbar-friday-bg-mobile-text {
    text-align: right;
    color: #373737;
    /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    font-family: 'Lexend';
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
}

@media screen and (max-width: 600px) {
    .topbar-friday {
        display: none;
    }

    .topbar-friday-bg {
        width: 100%;
    }

    .topbar-friday-mobile {
        display: block;
    }
}