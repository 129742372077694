@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
    background: inherit;
    color: inherit;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lexend', sans-serif;
  -webkit-text-size-adjust: none;
  /* border: 1px solid #000; */
}

body {
  font-family: 'Lexend', sans-serif;
  background-color: #ffffff;
  text-align: center;
  overflow-x: hidden;
  scrollbar-width: none;
}

img,
video {
  max-width: none;
  /* height: auto; */
}

*:not(.show-scroll)::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-thumb {
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #d9d9d9;
}

.small-rate .ant-rate-star:not(:last-child) svg {
  margin-right: -6px;
}

.ant-select-selection-item {
  font-weight: 300 !important;
}

.ant-rate {
  display: flex;
  flex-direction: row;
}

.calendly-overlay .calendly-popup {
  width: 100%;
  height: 100%;
}

.ant-rate-star.ant-rate-star-full:not(:first-child) {
  margin-left: -6.5px;
}

.ant-rate-star.ant-rate-star-zero:not(:first-child) {
  margin-left: -6.5px;
}

.ant-rate-star.ant-rate-star-half:not(:first-child) {
  margin-left: -6.5px;
}

.ant-select-dropdown {
  z-index: 99999;
}

.ant-popover {
  position: fixed;
}

.ant-message {
  z-index: 10000;
  /* Replace with your desired z-index value */
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.pagination {
  width: fit-content;
  display: inline-block;
}

.pagination ul {
  display: flex;
  width: fit-content;
  height: fit-content;
}

/* .pagination ul>li {
  display: flex;
  width: 20px;
} */

.pagination ul > li > a {
  color: black;
  float: left;
  text-decoration: none;
  cursor: pointer;
}

.pagination ul > li.active {
  font-weight: bold;
  border: 2px solid #000;
}

.pagination ul > li {
  border: 2px solid rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
}

.pagination ul > li:hover {
  font-weight: bold;
  background-color: #ddd;
}

.pagination ul > li.active > a:hover {
  padding: 0;
}

.pagination .previous,
.next {
  background-color: #ddd;
}

.pagination .disabled {
  background-color: #ddd;
}

.pagination .break {
  background-color: none;
}

.pagination-prev {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
}

.pagination-next {
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-message {
  z-index: 999999;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 960px) {
  .pagination ul {
    flex-wrap: wrap;
  }

  .pagination ul > li {
    width: 35px;
    height: 35px;
  }
}
