.cookie-wrapper {
  text-align: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: center; */
  gap: 10px;
  font-family: 'Lexend', sans-serif;
}

.cookie-content-title {
  font-size: 2rem;
  font-weight: 500;
  text-align: left;
}

.cookie-content-desc {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.cookie-btn {
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: black;
  color: white;
  border: 2 px solid black;
  padding: 10px;
  border-radius: 50px;
  flex-wrap: wrap;
}

.cookie-btn-accept {
  background-color: #e4b456;
  color: white;
}

.cookie-btn-reject {
  background-color: black;
  color: white;
}

.cookie-btn-customize {
  background-color: #eee;
  color: #333;
}

.cookie-switch {
  /* border: 1px solid #727272; */
  background-color: #f9f1e3;
  color: #202020;
}

.cookie-switch .ant-switch-inner,
.ant-switch-checked {
  background-color: #f9f1e3;
}

.cookie-switch .ant-switch-inner .ant-switch-inner-checked {
  color: #202020;
}

.cookie-switch .ant-switch-inner .ant-switch-inner-unchecked {
  color: #202020;
}

.cookie-switch .ant-switch-handle::before {
  /* background-color: #E4B456; */
}

.cookie-wrapper [type='button'] {
  background: inherit !important;
  background-color: #999 !important;
}

.cookie-wrapper .ant-switch.ant-switch-checked {
  background-color: #E4B456 !important;
}

@media screen and (max-width: 960px) {
  .cookie-content-title {
    font-size: 16px;
  }
}
