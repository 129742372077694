.inventory-wraper {
    display: flex;
    flex-direction: column;
    background: #fff;
    gap: 20px;
}

.csv-container {
    margin: 0 auto;
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    padding: 1em;
    background: #eee;
    display: flex;
    justify-content: center;
    align-content: center;
}

.csv-section {
    width: 100%;
    gap: 10px;
}

.inventory-section {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 960px;
    gap: 10px;
}

.inventory-section h3 {
    font-size: 30px;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.inventory-section h4 {
    font-size: 25px;
    font-weight: 300;
    margin: 0;
    padding: 0;
}

.inventory-section h4 strong {
    color: #ff0000;
}

.inventory-section p {
    font-weight: normal;
    width: 100%;
    max-width: 960px;
    font-size: 16px;
    line-height: 25px;
}

.format-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.format-list h3 {
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 0.3em;
}

.format-list-links {
    display: flex;
    gap: 20px;
}

.format-list-links a {
    text-decoration: underline;
}

.banner-section {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 40px;
    font-weight: 600;
    text-shadow: 2px 2px 4px #000000;
}

.message-error {
    width: 100%;
    background-color: #FFF2F0;
    border-radius: 5px;
    padding: 20px;
    color: #000;
    border: #ffd9d5 2px solid;
    border-radius: 10px;
}

.message-success {
    background-color: #23b89a;
    border-radius: 5px;
    padding: 20px;
    color: #fff;
}

@media screen and (max-width: 960px) {
    .csv-container {
        width: 100%;
    }
}