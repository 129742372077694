.store-google-map-popover {
    border: 2px solid #7B7B7B;
    border-radius: 22px;
}

.store-google-map-popover .ant-popover-arrow:before {
    border-top-color: #7B7B7B;
}

.store-google-map-popover .ant-popover-arrow:before {
    background: #7B7B7B;
}

.marker-label {
    position: relative;
    top: -20px;
    background-color: #fff;
    border: 2px solid #7B7B7B;
    padding: 2px;
}