/* footer */
footer {
  width: 100%;
  background-color: #202020;
  color: #fff;
  padding-top: 100px;
  padding-bottom: 100px;
}

.footer-content {
  width: 100%;
  margin: 0 auto;
  display: flex;
  gap: 50px;
}

.footer-logo {
  /* padding-left: 100px; */
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
}

.footer-social {
  text-align: left;
  display: flex;
  gap: 16px;
}

.footer-social img {
  width: 29px;
}

.footer-links {
  display: flex;
  gap: clamp(100px, 10vw, 150px);
  text-align: left;
  font-size: 18px;
  font-weight: 400;
}

.footer-links ul {
  display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));


  gap: 20px;
}

.footer-links ul li:hover {
  text-decoration: 2px underline;
}

/* .footer-copy {
  text-align: center;
  font-size: 14px;
  padding-top: 100px;
} */

@media screen and (max-width: 960px) {
  footer {
    padding-left: 0px;
  }

  .footer-logo {
    padding-left: 0px;
    justify-content: center;
    align-items: center;
  }

  .footer-content {
    width: 100%;
    flex-direction: column;
  }

  .footer-links {
    flex-direction: column;
    gap: 20px;
    font-size: 16px;
    justify-content: center;
    /* align-items: center; */
    /* justify-content: space-between; */
  }

  .footer-links ul {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  
}
}
