.ant-carousel-vertical .slick-dots {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 20px;
  border-radius: 20px;
  background-color: black;
  display: flex;
  justify-items: center;
  align-items: center;
  opacity: 50%;
}

.ant-carousel-vertical .slick-dots li {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  justify-items: center;
  align-items: center;
  opacity: 100%;
}

.ant-carousel-vertical .slick-dots li.slick-active {
  background-color: black;
}

.swipe-container {
  overflow: hidden;
  position: relative;
}