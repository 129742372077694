.favorite-switch {
    border: 1px solid #727272;
    background-color: #F9F1E3;
    color: #202020;
}

.favorite-switch .ant-switch-inner,
.ant-switch-checked {
    background-color: #F9F1E3;
}

.favorite-switch .ant-switch-inner .ant-switch-inner-checked {
    color: #202020;
}

.favorite-switch .ant-switch-inner .ant-switch-inner-unchecked {
    color: #202020;
}

.favorite-switch .ant-switch-handle::before {
    background-color: #E4B456;
}

.offer-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}