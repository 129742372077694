.register-wrapper {
  margin: 0 auto;
  width: 100%;
  background-color: #fff;
  height: 100%;
}

.register-top {
  text-align: left;
  padding: 20px;
  display: flex;
  gap: 10px;
  font-weight: bold;
  align-items: center;
}

.register-main {
  margin: auto;
  width: 100%;
  padding: 10px;
}

.register-main-logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.register-main-form {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  color: #000;
}

.register-main-form h3 {
  font-size: 30px;
  text-align: center;
}

.register-form {
  width: 100%;
  max-width: 650px;
  display: flex;
  align-items: center;
  gap: 20px;
  color: #000;
}

.register-form-item .ant-form-item-label > label {
  color: #000;
}

.register-button {
  width: 100%;
  max-width: 150px;
  padding: 6px;
  border-radius: 8px;
  background-color: #bf9000;
  border: #bf9000 2px solid;
  color: #000;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
}

.register-button:hover {
  background-color: #f3f3f3;
  color: #bf9000;
}

.register-button-secondary {
  background-color: #f3f3f3;
  color: #bf9000;
}

.register-form-footer {
  padding-bottom: 20px;
}

.register-form-left h3,
.register-form-right h3 {
  font-size: 20px;
  color: #bf9000;
  text-align: center;
}

.register-form-left {
  flex: 1;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.register-form-input {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.register-form-input span {
  color: #ff0000;
}

.register-form-input label {
  text-align: left;
}

.register-form-input input {
  padding: 5px;
  border-radius: 5px;
  border: none;
  background-color: #000;
  color: #000;
  font-size: 16px;
}

.register-form-input select {
  padding: 7px;
  border-radius: 5px;
  border: none;
  background-color: #000;
  color: #000;
  font-size: 16px;
}

.register-form-right {
  flex: 1;
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.register-result {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  gap: 20px;
  background-color: #717070;
  border-radius: 10px;
  padding: 30px;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-bottom: 20px;
}

.register-result h3 {
  font-size: 30px;
  color: #bf9000;
  text-align: center;
}

.register-result p {
  color: #fff;
  font-weight: normal;
}

.register-result h4 {
  color: #bf9000;
  font-weight: bold;
  text-decoration: underline;
}

.register-result-icons {
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: center;
}

.register-result-icons img {
  width: 60px;
}

/** Steps **/
.register-steps {
  padding: 20px;
  color: #fff;
}

.register-steps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #bf9000;
  border: #bf9000 1px solid;
}

.register-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  font-size: 20px;
  color: #bf9000;
  text-align: center;
}

.register-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #f3f3f3;
  text-align: center;
}

.register-steps .ant-steps-item-wait .ant-steps-item-icon {
  background-color: #f3f3f3;
  border: #f3f3f3 1px solid;
}

.register-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #bf9000;
  top: -3px;
}

.register-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #f3f3f3;
  border: #f3f3f3 1px solid;
}

.register-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #999;
  text-align: center;
}

.register-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #999;
}

.register-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #bf9000;
}

.register-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background-color: #f3f3f3;
}

.register-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  color: #bf9000;
}

.register-form-item .ant-form-item-label > label {
  color: #000;
  padding-left: 10px;
}

.ant-input-affix-wrapper .ant-input-clear-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.register-button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.register-input-line {
  /* display: flex; */
  gap: 20px;
  width: 100%;
}

.retail-form .ant-form-item{
  flex:1
}
@media screen and (max-width: 960px) {
  .register-main {
    width: 95%;
    max-width: none;
  }

  .register-form {
    flex-direction: column;
    padding: 10px;
    gap: 20px;
  }

  .register-input-line {
    width: 100%;
    flex-direction: column;
    gap: 10px;
  }

  .register-form-content {
    width: 100%;
    max-width: none;
  }
}
