.detail * {
  color: white;
}

.item-header {
  width: 100%;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
}

.button-continue {
  background-color: #fff;
  padding: 5px 5px;
  border-radius: 50px;
  border: 1.5px solid #000;
  color: #000;
  font-size: 16px;
  line-height: 25px;
  font-weight: 450;
  text-transform: uppercase;
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 230px;
}

.item-continue-shopping-fixed-wrap {
  width: 100%;
  top: 80px;
  position: fixed;
  background-color: #fff;
  -moz-box-shadow: 0 5px 5px rgba(182, 182, 182, 0.75);
  -webkit-box-shadow: 0 5px 5px rgba(182, 182, 182, 0.75);
  box-shadow: 0 5px 5px rgba(182, 182, 182, 0.75);
  z-index: 1100;
}

.item-continue-shopping-fixed {
  width: 1280px;
  padding: 10px;
  padding-left: 50px;
  padding-right: 50px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-continue-shopping-fixed-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.item-continue-shopping-fixed-title h3 {
  font-size: 22px;
  font-weight: 450;
  text-transform: uppercase;
}

.reserve-popup {
  /* width: 300px; */
}

.reserve-wrap {}

.blackfriday-detail-notice {
  width: 100%;
  background-color: #FABA3D;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  justify-content: left;
  align-items: center;
  text-align: left;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}

.triangle2 {
  width: 0;
  height: 0;
  border-right: 15px solid #fff;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
}

.triangle {
  position: absolute;
  /* position: relative; */
  right: -15px;
  width: 25px;
  height: 26.5px;
  z-index: 0;
  background-image:
    linear-gradient(45deg, #E4B456 50%, rgba(255, 255, 255, 0) 50%),
    linear-gradient(135deg, #E4B456 50%, rgba(255, 255, 255, 0) 50%);
}

.detail-content {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  line-clamp: 10;
  -webkit-box-orient: vertical;
  /* white-space: normal; */
}

.imglist-icons-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
  /* align-items: center;
  justify-content: center; */
  gap: 10px;
}

/* .item-image-container {
  width: 100%;
  height: 100%;
} */

.item-image-container img {
  width: 100%;
  height: 100%;
  background-size: contain;
  border-radius: 10px;
}

@media screen and (max-width: 960px) {
  .item-header {
    width: 100%;
    padding-left: 20px;
    padding-top: 20px;
  }

  .item-continue-shopping-fixed-wrap {
    top: 135px;
  }

  .item-continue-shopping-fixed {
    width: 100%;
    padding-left: 20px;
  }

  .item-continue-shopping-fixed-title {
    display: none;
  }

  .reserve-popup {
    width: 100%;
    margin: 10px;
  }

  .reserve-wrap {
    align-items: start;
  }
}