.zsearch-input {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  /* gap: 10px; */
  color: #000;
}

.zsearch-input input {
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 300;
  width: 100%;
  font-family: 'Lexend', sans-serif;
}

.zsearch-input button {
  border-radius: 100%;
  border: none;
  outline: none;
  background-color: #fff;
  cursor: pointer;
  background-image: url('/public/logo192.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.zsearch-input-new {
  font-size: 15px;
  font-weight: 300;
  font-family: 'Lexend', sans-serif;
}

.zseach-history-suggestion {
  z-index: 99999;
}