.profile-avatar {
 font-size: 80px
}
.profile {
 height: calc( 100vh - 80px);
}

.profile-form {
  width: 600px;
}
.ant-modal-content{
  text-align: left;
}

@media screen and (max-width:750px) {
  .profile-form {
    width: 300px;
  }
  
}