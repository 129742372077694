/* join section */
.landing-join {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fff;
  /* padding-top: 150px;
  padding-bottom: 150px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.landing-join-content {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.landing-join-content img {
  min-width: 656px;
}

.landing-join-content h1 {
  font-size: 35px;
  font-weight: 600;
}

.landing-join-content p {
  font-size: 26px;
  font-weight: 400;
}

.landing-join-content button {
  margin-top: 10px;
  /* width: 210px;
  height: 41px; */
  line-height: 18px;
  border-radius: 25px;
  /* padding: 5px 30px; */
  background-color: #fff;
  color: #000;
  font-weight: 400;
  border: #000 1px solid;
  cursor: pointer;
  text-transform: uppercase;
}

.landing-join-content button:hover {
  background-color: #b6b6b6;
  /* color: #e4b456; */
}

@media screen and (max-width: 960px) {
  .landing-join {
    width: 100%;

  }

  .landing-join-content {
    width: 100%;
    gap: 10px;
    padding: 0 20px;
    flex-direction: column-reverse;
  }

  .landing-join-content img {
    min-width: 100%;
    width: 100%;
  }

  .landing-join-content h1 {
    font-size: 25px;
  }

  .landing-join-content p {
    font-size: 18px;
  }
}