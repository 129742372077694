.mobile-items-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.mobile-item-card {
    width: 100%;
    display: flex;
    gap: 5px;
    /* border-radius: 5px; */
    /* border: #e9e9e9 1px solid; */
    flex-direction: row;
    align-items: stretch;
}

/* .mobile-item-card:hover {
    background-color: #f5f5f5;
    border: #e0e0e0 1px solid;
} */

.mobile-item-img {
    /* width: 50%; */
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: #e9e9e9 1px solid;
    /* height: 100%; */
    /* max-height: 180px; */
}

.mobile-item-img img {
    width: 100%;
    height: 100%;
    max-width: 150px;
    max-height: 165px;
    object-fit: contain;
    border-radius: 5px;
}

.mobile-item-content {
    flex: 1;
    /* width: 50%; */
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 5px;
}

.mobile-item-content h4 {
    font-size: 16px;
    font-weight: 300;
}

.mobile-item-price {
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    /* position: relative; */
    display: flex;
}

.mobile-item-price::before {
    font-size: 14px;
    font-weight: 300;
    /* position: absolute; */
}

.item-live-inventory {
    border: 1.5px solid #000;
    border-radius: 0px;
    color: #000;
}