.header-wrapper {
  height: 100px;
  background-color: #202020;
  display: flex;
  align-items: center;
  color: #fff;
  /* z-index: 10; */
  width: 100%;
}

.header-logo {
  padding-left: 30px;
  flex: 1;
}

.scroll-element {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.scroll-element.show {
  opacity: 1;
}

.header-search {
  flex: 4;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.header-search-mobile {
  /* flex: 4; */
  display: none;
  padding: 10px;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.common-input {
  width: 300px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
  font-size: 14px;
  color: #000;
}

.common-button {
  background-color: transparent;
  padding: 5px;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  border-radius: 5px;
  border: #fff 1px solid;
}

.header-right {
  display: flex;
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding-right: 30px;
  gap: 20px;
  flex: 2;
}

.header-address {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  gap: 3px;
  cursor: pointer;
}

.navbar {
  margin: 0 auto;
  width: 100%;
  /* background-color: #fff; */
  position: static;
  z-index: 100;
}

.navbar-scroll {
  position: fixed;
  top: 0;

  z-index: 9999;
}

.avatar {
  color: #000;
  background-color: #fff;
  vertical-align: middle;
  font-size: 25px;
  cursor: pointer;
  /* font-weight: bold; */
}

/* @media screen and (max-width: 480px) {
  .header-logo {
    padding: 100px;
    flex: 1;
  }
} */

.menu-icon {
  font-size: 40px;
}


/* Dropdown Button */
.dropbtn {}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 1200;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}


@media screen and (max-width: 600px) {
  .header-search {
    display: none;
  }

  .header-search-mobile {
    display: flex;
  }

  .header-logo {
    padding-left: 10px;
  }

  .header-right {
    gap: 10px;
    padding-right: 10px;
  }

  .menu-icon {
    font-size: 30px;
  }

  .header-address {
    font-size: 14px;
    gap: 1px
  }

  /* .header-wrapper {
    padding-top: 18px;
  } */

  /* .header-location {
    display: none;
  } */
}