.pagination-item {}

.pagination-page {
    /* width: 44px; */
    width: 100%;
}

.pagination-page a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}