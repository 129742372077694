:root {
  --filter-size: 300px;
}

.truncate-multiline {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Number of lines to show before truncating */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.truncate-multiline-profile {
  display: -webkit-box;
  -webkit-line-clamp: 7;
  /* Number of lines to show before truncating */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.content-wraper {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: stretch;
  -webkit-align-items: stretch;
  flex-direction: row;
}

.filter-wrapper {
  /* padding-top: 25px; */
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  max-height: 100vh;
  width: var(--filter-size);
  transition: all 0.3s ease;
  z-index: 1;
}

.filter-content {
  height: 100%;
  /* padding-right: 6px; */
  overflow: hidden;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  /* padding-left: 4px;
  margin-left: -4px; */
}

.filter-content:hover {
  overflow-y: auto;
}

.filter-content::-webkit-scrollbar {
  width: 12px;
  scrollbar-color: transparent transparent;
}

.filter-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.filter-content::-webkit-scrollbar-track-piece {
  background: #f1f1f1;
  border-radius: 6px;
}

.filter-content::-webkit-scrollbar-thumb:vertical {
  width: 10px;
  background: #aaa;
  border-radius: 6px;
}

/* .filter-content::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

.items-wrapper {
  background-color: #ffffff;
  min-width: calc(100vw - var(--filter-size));
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  /* max-height: 100vh;
  --max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden; */
}

.item-container {
  width: 100%;
  display: flex;
  margin: 0 auto;
  border: #6a6a6a 3px solid;
  border-radius: 10px;
  padding: 10px;
  gap: 20px;
}

.item-display-image {
  flex: 1;
  padding-left: 5px;
  padding-top: 10px;
}

.item-display-info-wrapper {
  display: flex;
  gap: 1px;
  flex-direction: column;
  text-align: left;
  flex: 3;
  border-right: #6a6a6a 2px solid;
}

.item-display-info-wrapper h3 {
  font-size: 35px;
  font-weight: 700;
  color: #000;
}

.tag-text {
  color: #6a6a6a;
  font-size: 20px;
  font-weight: 500;
  padding-top: 10px;
}

.tag-text p {
  color: rgb(0, 0, 0);
  font-weight: 700;
  font-size: 20px;
  padding-top: 10px;
}

.item-display-info-button-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: right;
  flex: 1;
}



.item-display-info-star {
  display: flex;
  gap: 5px;
}

.new-filter-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.commom-button {
  border-radius: 5px;
  padding: 3px 20px;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  background-color: #bf9000;
  border: #bf9000 2px solid;
  color: white;
  border-radius: 10px;
}

.commom-button:hover {
  background-color: #fff;
  color: #bf9000;
  border: #bf9000 2px solid;
}

.items-content {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* .items-list{
 grid-auto-rows: auto;
} */

.item-display-info-price {
  font-size: 30px;
  font-weight: 600;
  text-align: left;
}

.items-no-result {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.items-content-list {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  /* padding-left: 40px;
  padding-right: 40px; */
}

.items-no-result h4 {
  color: #000;
  font-weight: normal;
}

.items-content-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  /* padding: 10px; */
}

.items-content-header h2 {
  color: #000;
  font-size: 24px;
  font-weight: 700;
}

.items-sortby select {
  padding: 3px;
  border-radius: 5px;
  border: #6a6a6a 2px solid;
  font-size: 16px;
}

.items-no-result h3 {
  color: #000;
}

.new-filter-wrapper {
  padding: 20px;
  background-color: #595959;
  flex: 1;
  text-align: left;
  /*    height: 100vh; */
  height: 100%;
  min-height: 100vh;
  scroll-behavior: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100px;
}

.new-filter-wrapper h3 {
  display: flex;
  color: white;
  font-weight: bold;
  font-size: 20px;
  gap: 10px;
  cursor: pointer;
  align-items: center;
}

.filter-item {
  color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-bottom: #727272 1px solid;
  padding-bottom: 20px;
  /* max-height: 20rem; */
  /* overflow-y: scroll; */
  margin-bottom: 20px;
}

.filter-item-list {
  color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 20px;
  max-height: 20rem;
  overflow-x: hidden;
  overflow-y: auto;
  /* scrollbar-color: red yellow; */
  scrollbar-width: none;
}

.filter-item-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  position: sticky;
}

.filter-item-title h4 {
  font-weight: 600;
}

.filter-item span {
  cursor: pointer;
  width: 200px;
}

.filter-item-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 15px;
  gap: 10px;
}

.filter-item-header h4 {
  display: flex;
  font-size: 16px;
}

.filter-item-list::-webkit-scrollbar {
  display: none;
}

.filter-price-input {
  display: flex;
  gap: 10px;
  width: 100%;
  height: 40px
}

.filter-price-input .ant-form-item-explain-error {
  font-size: 12px
}

.filter-price-input span {
  cursor: auto;
  width: fit-content;
}

.filter-item-price {
  /* padding-left: 10px; */
  cursor: pointer;
}

.filter-price-input .price-input {
  width: 80px;
  border-radius: 4px;
  font-size: 16px;
  color: #000;
  height: 28px;
  font-family: 300;
}

.filter-price-input button {
  width: 30px;
  font-size: 16px;
  border: #000 1px solid;
  border-radius: 4px;
  color: #000;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-price-item {
  width: 85px;
  height: 28px;
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #fff;
  border-radius: 4px;
  color: #000;
  font-weight: 300;
  padding-left: 5px;
  padding-right: 5px;
}

.filter-price-item span {
  color: #000;
  font-size: 16px;
}

.filter-price-item input {
  border: none;
  width: 60px;
  font-size: 13px;
}

.filter-price-item input:focus {
  outline: none;
}

.ant-tree .ant-tree-checkbox-checked:not(.ant-tree-checkbox-disabled):hover .ant-tree-checkbox-inner {
  background-color: #e4b456;
  border-color: #e4b456;
}

.ant-tree .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #e4b456;
  border-color: #e4b456;
  width: 18px;
  height: 18px;
}

.ant-tree .ant-tree-checkbox .ant-tree-checkbox-inner {
  width: 18px;
  height: 18px;
}

.ant-tree .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after {
  background-color: #e4b456;
  border-color: #e4b456;
}

.ant-tree .ant-tree-checkbox+span {
  padding-left: 2px;
}

.ant-tree .ant-tree-checkbox-checked .ant-tree-checkbox-inner:after {
  opacity: 1;
  transform: none;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: url('/public/images/Checkboxmark.png');
  display: block;
  text-align: center;
  font-size: 16px;
  top: 1px;
  left: 1px;
  color: #202020;
}

.ant-tree .ant-tree-checkbox .ant-tree-checkbox-inner:after {
  border: none;
}

.ant-tree .ant-tree-checkbox+span.ant-tree-node-selected {
  background-color: #202020;
}

.filter-checkbox-item {
  /* padding-left: 10px; */
  /* display: flex; */
  gap: 5px;
  align-items: center;
  /* justify-content: center; */
}

.filter-checkbox-item label {
  /* cursor: pointer; */
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400px;
  gap: 10px;
  /* line-height: 20px; */
}

.filter-checkbox-item input {
  width: 5px;
  border-radius: 4px;
  min-height: 5px;
  /* cursor: pointer; */
  /* padding-top: 4px; */
  /* padding-bottom: 4px; */
}

.item-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  /* align-items: center;
    justify-items: center; */
}

.new-item-wapper {
  padding: 20px;
  /* width: 100%;
    max-width: 300px; */
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: #333 2px solid;
  cursor: pointer;
  justify-content: space-between;
  border-radius: 10px;
}

.new-item-info {
  flex: 3;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.new-item-info h4 {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.new-item-wapper:hover {
  background-color: #eee;
}

.new-item-category-brand {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.new-item-info-location {
  margin-bottom: 10px;
}

.new-item-info-location span {
  float: right;
}

.new-item-info-desc {
  flex: 2;
}

.new-item-info-store {
  /* justify-content: flex-end; */
  display: flex;
  gap: 10px;
  /* justify-content: center; */
  align-items: center;
}

.new-item-other {
  display: flex;
  /* flex-direction: row-reverse; */
  text-align: left;
  align-items: center;
  justify-content: space-between;
}

.new-item-other h3 {
  font-size: 24px;
  font-weight: 600;
  /* color: #DCC453; */
}

.new-item-other p {
  text-align: right;
}

/* button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
} */

.on {
  color: #bf9000;
}

.off {
  color: #ccc;
}

.wishItem {
  color: #bf9000;
  font-size: 32px;
}

.new-item-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-item-info-location {
  font-size: small;
}

.store_name {
  white-space: normal;
  height: 40px;
  text-overflow: ellipsis;
  /* text-align: left; */
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  margin-top: 10px;
  line-height: 110%;
  /* 13.39px */
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.drop select::-ms-expand {
  display: none;
}

.filter-selected-list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 10px 0;
  /* border-bottom: #ccc 1px solid; */
  /* margin-bottom: 10px; */
}

/* .filter-selected-clear-all {
  font-size: 13px;
  color: #e4b456;
} */

.filter-selected-clear-all {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 50px;
  color: #818181;
  white-space: nowrap;
  width: 70px;
  /* width: fit-content; */
  /* text-transform: uppercase; */
  cursor: pointer;
  /* underline */
  text-decoration: underline;
  font-weight: 400;
}

.filter-selected-item {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  border-radius: 50px;
  background-color: #eaeaea;
  color: #000;
  /* max-width: 200px; */
  width: fit-content;
  text-transform: uppercase;
  gap: 5px;
  /* flex-wrap: nowrap; */
}

.filter-selected-item .filter-selected-item-label {
  font-family: 'Lexend', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  -webkit-line-clamp: 1;
  width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  /* text-overflow: ellipsis; */
}

.filter-selected-item-close {
  cursor: pointer;
  color: #8A8A8A;
}

.item-unwanted-wrap {
  display: flex;
  padding: 5px;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}

.search-stores-list {
  width: 100%;
  display: flex;
  gap: 24px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.filter-side-selected-mobile {
  width: 100%;
  background-color: #fff;
  color: #000;
  position: fixed;
  border-bottom: none;
  margin: 0;
  padding: 10px;
  z-index: 99;
  -moz-box-shadow: 0 5px 5px rgba(182, 182, 182, 0.75);
  -webkit-box-shadow: 0 5px 5px rgba(182, 182, 182, 0.75);
  box-shadow: 0 5px 5px rgba(182, 182, 182, 0.75);
}

.filter-mobile .ant-drawer-extra {
  width: 250px;
  text-align: right;
}

.filter-top-mobile {
  width: 100%;
  background-color: #fff;
  color: #000;
  position: fixed;
  border-bottom: none;
  margin: 0;
  padding: 10px;
  z-index: 99;
  -moz-box-shadow: 0 5px 5px rgba(182, 182, 182, 0.75);
  -webkit-box-shadow: 0 5px 5px rgba(182, 182, 182, 0.75);
  box-shadow: 0 5px 5px rgba(182, 182, 182, 0.75);
}

.filter-mobile-drawer .ant-drawer-header {
  padding: 0;
  padding-right: 15px;
  padding-top: 15px;
  border-bottom: none;
}


@media screen and (max-width: 650px) {
  .item-list {
    /* grid-template-columns: repeat(1, 1fr); */
    grid-template-columns: repeat(auto-fit, 1fr);
  }
}

@media screen and (max-width: 1400px) {
  .items-content-list {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .content-wraper {
    flex-direction: column;
  }

  .store_name {
    font-size: 13px;
    height: 30px;
  }

  .item-container {
    width: 100%;
    flex-direction: column;
  }

  .items-wrapper {
    width: 100%;
  }

  .item-display-info-wrapper {
    border-right: none;
    border-bottom: #6a6a6a 2px solid;
  }

  .filter-wrapper {
    display: none;
  }

  .new-filter-wrapper {
    height: 100%;
    width: 100%;
    min-height: auto;
  }

  .new-filter-content {
    display: none;
  }

  .new-item-wapper {
    flex-direction: column;
    gap: 10px;
  }

  .new-item-other {
    flex-direction: row;
    justify-content: space-between;
  }

  .search-stores-list {
    overflow-x: auto;
    gap: 10px;
  }
}