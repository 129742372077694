.blog-side {
  padding: 20px;
  flex: 1;
  background-color: #fff;
  box-shadow: #bbb 0px 0px 10px;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.blog-side h3 button {
  float: right;
  border: none;
  padding: 5px;
  background-color: #B33030;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
}

.new-input-radio-checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.new-filter-item {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 20px;
}

.blog-filter-item span {
  float: right;
}

.blog-filter-item label {
  cursor: pointer;
}

.blog-filter-types {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  gap: 10px;
}

.blog-type-title {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.blog-type-child {
  padding-left: 20px;
}

.blog-filter-item {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
}

@media screen and (max-width: 960px) {
  .blog-side {
    margin: 10px;
  }
}