.backToTop {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 62px;
  height: 62px;
  background-color: #fff;
  color: #323232;
  cursor: pointer;
  font-size: 18px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20;
  border: #323232 2px solid;
  inset-inline-end: 24px;
  inset-block-end: 48px;
}

.backToTop:hover {
  border: #323232 2px solid;
  background-color: #f3f3f3;
  color: #323232;
}