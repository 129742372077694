.login-wrapper {
  margin: 0 auto;
  width: 100%;
  background-color: #fff;
  height: 100%;
  /* background-color: #f5f5f5; */
}

.login-top {
  text-align: left;
  padding: 20px;
  display: flex;
  gap: 10px;
  font-weight: bold;
  /* justify-content: center; */

  align-items: center;
}

.login-main {
  margin: 0 auto;
  width: 100%;
  max-width: 550px;
  padding: 10px;
}

.login-main-logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.account-select-buttons {
  gap: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.account-select-buttons button {
  width: 300px;
  border-radius: 50px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #e4b456;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.register-form-footer {
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  gap: 4px;
}

.account-select-divider {
  width: 200px;
  height: 2px;
  margin: 0 10px;
  background-color: rgba(255, 255, 255, 0.1);
}
