.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
    gap: 20px;
}

.ant-spin .ant-spin-dot-item {
    background-color: #000;
}

.loading-content {
    padding: 20px;
    /* background: rgba(0, 0, 0, 0.05); */
    /* border-radius: 4px; */
}