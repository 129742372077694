/* near store section */
.stores {
  position: relative;
  width: 100%;
  max-width: 1228px;
  margin: 0 auto;
  /* padding: 0 10px; */
  /* margin-top: 65px; */
  /* margin-bottom: 130px; */

  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  /* margin-bottom: 130px; */
}

.stores h2 {
  font-size: 22px;
  font-weight: 400;
  /* margin-bottom: 20px; */
  text-align: left;
  text-transform: uppercase;
}

.stores-list {
  width: 100%;
  display: flex;
  gap: 24px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.stores-item {
  min-width: 184px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  /* gap: 10px */
}

.stores-item img {
  border-radius: 92px;
  width: 184px;
  height: 184px;
  border: #ccc 3px solid;
}

.stores-item-name {
  border-radius: 92px;
  width: 184px;
  height: 184px;
  border: #ccc 3px solid;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stores-item-info {
  width: 100%;
  text-align: left;
  padding: 8px;
}

.stores-item-info h3 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
  /* line-height: 1.1rem; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2em;
  max-height: 2.4em;
  height: 40px;
}

.stores-item-info p {
  font-size: 16px;
  font-weight: 300;
  line-height: 0.8rem;
}

.stores-item-info span {
  font-size: 13px;
}

.stores-more {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: #cd9426;
}

@media screen and (max-width: 960px) {
  .stores {
    width: 100%;
    padding: 0 10px;
    margin-top: 13px;
    margin-bottom: 10px;
  }

  .stores h2 {
    font-size: 15px;
  }

  .stores-list {
    overflow-x: auto;
    gap: 10px;
  }

  .stores-item {
    min-width: 103px;
  }

  .stores-item img {
    border-radius: 51.5px;
    width: 103px;
    height: 103px;
    border: #ccc 3px solid;
  }

  .stores-item-name {
    width: 103px;
    height: 103px;
    border-radius: 51.5px;
    font-size: 15px;
    line-height: 15px;
  }
}